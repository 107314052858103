input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 100%;
  }
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  /* .scrollable{
    overflow-y: scroll;
    width: 170px; 
    height: 100px; 
    border: 1px silver solid;
  } */

  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    /* height: 500px; */
    /* overflow-y: scroll; */
    padding-left: 0;
    width: 100%;
  }

  ul.suggestions {
    overflow-y: scroll;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #4CB6CD;
    color: #fae042;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
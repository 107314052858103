.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: "Courier New";
 
}
.modal-dialog {
  max-width: none;
  margin: 0 20%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.main {
  background-image: url('./images/bg.jpg');
  min-height:877px;
  background-repeat:no-repeat;
  background-size:cover;
}

.logo {
  background-image: url('./images/klinik-logo.jpeg');
  min-height:200px;
  background-repeat:no-repeat;
  background-size:cover;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}

.mainToast {
  position: relative;
}

.registerToast {
  position: relative;
  left: 300px;
}

.table-search-result{
  width:78%;
  margin-left: 147px;
}

.modalClinic{
  padding: 10px;
  margin: 0px auto;
  width: 80%;
}

.bgSuccess{
  background-color: #00a302;
}
.bgWarning{
  background-color:#f51b00;
}

.header {
  background-color: #357EC7;
  height: 100px;
  padding: 32px 10px 10px 120px;
  font-size: 20px;
  font-family: "Courier New";
  margin: 0px;
  /* -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  border-radius: 13px; */
  position: fixed;
  top:0;
  width: 100%;
  z-index: 100;
 
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
  }
  .table-wrapper-scroll-y {
  display: block;
  }

.menu {
  -moz-border-radius: 13px;
  -webkit-border-radius: 13px;
  border-radius: 13px;
  margin:0px;
  position: fixed;
  top:100px;
  width: 100%;
  z-index: 100;
}

.content {
  margin:155px 14px 30px 10px;
  min-height:450px;
}

.footer{
  font-size: 16px;
  margin: 0 auto;
  width: 457px;
  color: cornflowerblue;
}
.consultationFooter {
  background-color: azure;
  bottom: 150px;
  position: fixed;
  width: 100%;
  padding: 10px
}
.home {

  background-image: url('./images/doc1.jpg');
  width: 357px;
  min-height: 400px;
  background-color: azure;
  margin:0px auto;

}

.container {
  position: relative;
}

.login {
  background-color: cadetblue;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}



#content-desktop, .drugDesktop, .loginDesktop, .header {display: block;}
#collasible-logout-dropdown, .drugMobile, .loginMobile{display: none;}

@media screen and (max-width: 768px) {

  #content-desktop, .drugDesktop, .loginDesktop, .header {display: none;}
  #collasible-logout-dropdown, .drugMobile, .loginMobile { display: block;}
  .menu {
    top: 0;
  }
  .content {
    top: 55px;
    margin: 55px 0px;
  }

}

/* Add an active class to the active dropdown button */
.active {
  background-color: green;
  color: white;
}

.formClinic {
  background-color: #e9edf3;
  padding: 30px;
  margin: 10px 100px;
 
}

.formMc {
  background-color: #e9edf3;
  padding: 50px 70px;
  margin: 10px 400px;
 
}

.formBig {
  background-color: #e9edf3;
  padding: 30px;
  margin: 10px 10px;
}

.consultHeader {
  background-color: #BCDFFA ;
  border: #E4FAF9 solid 1px;
 
}
.consultBody{
  background-color:#E4FAF9;
  margin-top: 1px;
}

.icon-margin {
  margin-left: 15px;
}
/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.App-link {
  color: #61dafb;
}

.nav-link[data-toggle].collapsed:before {
  content: " ▾";
}
.nav-link[data-toggle]:not(.collapsed):before {
  content: " ▴";
}

.noclick{
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

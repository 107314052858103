.docMobile {
    display: none;
}



@media screen and (max-width: 767px){
    .docMobile{
        width: 100%;
        height: auto;
        padding: 20px 0;
        display: block;
        position: absolute;
        top:50px;
        font-size: smaller;
     }
     .content {
        display: none;
     }

     .footer {
        width: 0px;
     }

     .formClinic {
        background-color: #e9edf3;
        padding: 30px;
        margin: 10px;
     }
    }
    

    .modal-dialog {
        margin: 0 10%;
    }

    @media screen and (max-width: 767px){
        .docMobile h1{
            font-size: 22px;
            font-size: 1.375em;
            padding: 10px 20px;
        }
        .modal-dialog {
            margin: 0;
           
          }
        }

       